export const AddCredential = () => import('../../components/AddCredential.vue' /* webpackChunkName: "components/add-credential" */).then(c => wrapFunctional(c.default || c))
export const CredentialPicker = () => import('../../components/CredentialPicker.vue' /* webpackChunkName: "components/credential-picker" */).then(c => wrapFunctional(c.default || c))
export const LineChart = () => import('../../components/LineChart.vue' /* webpackChunkName: "components/line-chart" */).then(c => wrapFunctional(c.default || c))
export const MiniAlert = () => import('../../components/MiniAlert.vue' /* webpackChunkName: "components/mini-alert" */).then(c => wrapFunctional(c.default || c))
export const Navbar = () => import('../../components/Navbar.vue' /* webpackChunkName: "components/navbar" */).then(c => wrapFunctional(c.default || c))
export const NuxtLogo = () => import('../../components/NuxtLogo.vue' /* webpackChunkName: "components/nuxt-logo" */).then(c => wrapFunctional(c.default || c))
export const Sidebar = () => import('../../components/Sidebar.vue' /* webpackChunkName: "components/sidebar" */).then(c => wrapFunctional(c.default || c))
export const WebAuthnConditionalComponent = () => import('../../components/WebAuthnConditionalComponent.vue' /* webpackChunkName: "components/web-authn-conditional-component" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
